import {createApp} from 'vue'
import App from './App.vue'

import vant from 'vant';
//import * as func from 'vant';
import 'vant/lib/index.css';

import 'animate.css';
import 'font-awesome/css/font-awesome.css'

const app = createApp(App)

/*import Vconsole from 'vconsole'

let vConsole = new Vconsole()
app.use(vConsole);
import eruda from 'eruda'

eruda.init()*/

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

//app.config.globalProperties.$vant = func;
app.use(VueVideoPlayer)
app.use(vant)
app.mount('#app')
