<template>
  <div id="app" @click="oneClicks">

    <div class="animate__animated animate__delay-1s animate__fadeInDown animate__slow"
         style="position:fixed;margin-left: 40%;z-index:999;height: 20px;line-height: 20px">
      <div class="timeC timeB" style="float: left">{{ ymds.year }}</div>
      <div class="timeC timeY" style="float: left">年</div>
      <div class="timeC timeB" style="float: left">{{ ymds.month }}</div>
      <div class="timeC timeY" style="float: left">月</div>
      <div class="timeC timeB" style="float: left">{{ ymds.day }}</div>
      <div class="timeC timeY" style="float: left">天</div>
    </div>
    <div @click="plays" style="z-index: 9999; position: fixed; margin-left: 86%; margin-top: 3%;">
      <div
          style="width: 35px;height: 35px;line-height: 35px;border: 2px solid rgb(255, 204, 49);border-radius: 70px;text-align: center;"
          :style="bjyyStyle"
      >
        <div class="fa fa-music fa-spin"></div>
      </div>
    </div>
    <audio id="audios" autoplay="autoplay" ref="audio" loop>
      <source
          src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/mcc3v-xcf9v.mp3"
          type="audio/mpeg"
      />
    </audio>

    <swiper
        ref="productSwiper"
        style="height: 100vh;width: 100%;"
        :modules="homeModules"
        :slides-per-view="1"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
    >
      <swiper-slide>
        <swiper
            :class="first.lb"
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125016.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125015.jpg">
          </swiper-slide>
        </swiper>
        <div class="gt" style="color: rgb(255, 255, 255); text-align: center; font-size: 4vw;overflow: hidden;">
          <p :class="first.six"><span style="font-size: 20px;">胡佑碧</span> 女士，生于1938年
          </p>
          <p style="margin-top: 10px;" :class="first.seven">
            2022年05月06日因病离开人世！</p>
          <p style="margin-top: 10px;" :class="first.eight">
            享年
            <span style="font-weight: bold; font-size: 20px; color: rgb(255, 223, 98);"> 84 </span>
            岁
          </p>
        </div>
        <van-divider>
          <van-button type="success" size="mini">记忆中的她</van-button>
        </van-divider>
        <span ref="texts"></span>
        <div style="color: #fff;font-size: 13px;width: 95%;margin: 0 auto;"
             :style="{height:height+'px',overflow:'auto'}">
          <div :class="first.one">
            &nbsp; &nbsp; &nbsp;&nbsp; 婆婆心好、爱开玩笑、心态乐观。却也是操劳命，总是在替我们操心，操心屋头事和每一个人！
          </div>
          <div :class="first.two">
            &nbsp; &nbsp; &nbsp;&nbsp;
            作者的童年和婆婆爷爷一起生活的时间比较多，小学2年级进封闭式学校，每月的上学和放学大部分是婆婆来接我，所以童年的大多时光都在老家！其实作者有个遗憾，没有让婆婆看到我的娃儿出生（以后我会带你的曾孙来看你）！因为我是陈家的唯一男丁，他们有时候也会拿这个开玩笑说：'你是陈家屋头的独苗苗，你的孙才是孙！'，其实我对这个并不感冒，但可能对他们老一辈的意义不一样！
          </div>
          <div :class="first.three">
            &nbsp; &nbsp; &nbsp;&nbsp;
            婆婆这辈子还是当了不少的媒人，在外面耍还是小区散步，遇到熟人或者是聊得起的老太婆闲聊大概率都会摆到哪个屋头的孙、孙女还单起的没结婚，然后就开始牵线搭桥了，'我勒边有个妹儿，好多好多岁，做的啥子工作，人才还可以！'。
          </div>
          <div :class="first.four">
            &nbsp; &nbsp; &nbsp;&nbsp; 我觉得肉体这个世界死去了，但意识还存在，意识存在于另一个我们看不到的维度空间！
          </div>
          <div :class="first.five">
            &nbsp; &nbsp; &nbsp;&nbsp; 希望，胡佑碧女士在另一个世界快快乐乐的生活下去。
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202204271512507.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125033.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2005 && 2000</van-button>
        </van-divider>

        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">
              <div :style="isStyle">
                <van-col span="24">
                  &nbsp; &nbsp; &nbsp;&nbsp; 她其实真的是个操心命，家里面不管是大的还是小的，都有一些她操心的事为你挂在心上。
                </van-col>
                <van-col span="24">
                  &nbsp; &nbsp; &nbsp;&nbsp; 有时会听到婆婆念：晓得莎莎妹哪个哪个事情啷个样了、鞠丽他们店头生意晓得好不好哦，一个月房租楞个高!、涛涛...、大双、小双、我 && 四姊妹们!
                </van-col>
                <van-col span="24">
                  &nbsp; &nbsp; &nbsp;&nbsp; 她偶尔会给你打电话，会问你"最近工作怎么样了，上班忙不忙，空了记到回去耍！！"。
                </van-col>
                <van-col span="24">
                  &nbsp; &nbsp; &nbsp;&nbsp; 真希望还可以看到她的来电，弥补上哪些嘘寒问暖的话... 但从前总是草草两句挂断！
                </van-col>
              </div>
            </van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">跳蹬-大竹林</van-col>
          </van-row>
        </div>

      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125031.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">20??-??-??</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">二嬢带婆婆出去耍</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">四面山?</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125032.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125023.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2020-05-20</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">老大在屋头办了一桌，提前给婆婆过80岁生日</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">竹园</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125024.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125035.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125025.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202204271512506.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2020-05-25</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">婆婆80岁生日</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">谢家湾 - 菊姐店头</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202204271512508.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125010.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202204271512509.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2021-02-18</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">带两位老人出去耍</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">融汇文旅城</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125014.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125012.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125018.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2021-03-25</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">&nbsp; &nbsp; &nbsp;&nbsp;现在经常会咳嗽、小腿偶尔会肿起来，走路都是瘸的。去医院检查结果是肺上有一小块阴影，医生怀疑可能是炎症，回家吃药调理，等3个月后再复查</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">璧山 -- 古道湾 && 湿地公园</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125028.jpg">
          </swiper-slide>
        </swiper>
        <van-divider>
          <van-button type="warning" size="mini">2022-04-19</van-button>
        </van-divider>
        <div class="common">
          <div ref="handle1">
            <van-row>
              <van-col span="4">
                <van-button size="mini" type="success">事件：</van-button>
              </van-col>
              <van-col span="20">
                <div :style="isStyle">
                  <van-col span="24">
                    &emsp;&emsp;婆婆的牙长得好，80几岁的人一颗牙没掉，笑起来整整齐齐一排！
                  </van-col>
                  <van-col span="24">
                    &emsp;&emsp;今天婆婆给老幺说：我想把遗照选起！万一哪天走了，也要有张好看的照片！！所以有了这张照片~
                  </van-col>
                  <van-col span="24">
                    &emsp;&emsp;病情开始严重了，太多的营养不能吸收形成腹水，开始3周左右腹水会撑胀肚皮，然后去医院抽，再后来1 - 2周，再后来2 -
                    3天腹水就能撑胀肚皮！于是腹水不用去医院抽了，医院直接留个孔+袋子自己在家抽！
                  </van-col>
                  <van-col span="24">
                    &emsp;&emsp;前一段时间还可以自己走路，现在因为大腿水肿只能坐或者躺着，出门只能用轮椅代替双脚！
                  </van-col>
                </div>
              </van-col>
            </van-row>
          </div>
          <div ref="handle2">
            <van-row class="marg">
              <van-col span="4">
                <van-button size="mini" type="primary">地点：</van-button>
              </van-col>
              <van-col span="20">璧山 -- 古道湾 && 湿地公园</van-col>
            </van-row>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125036.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125037.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125041.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_2022042715125039.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2022-04-25</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">&emsp;&emsp;之前肚子上预留的孔开始漏腹水，老大、老幺带婆婆去医院看怎么处理！ 最后医生建议只能缝针，因为伤口没有愈合能力了！</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">西区医院</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811023.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811024.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811026.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2022-04-30</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">吃几口稀饭都觉得很难受！！</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">跳蹬 - 家</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811027.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2022-05-04</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">&emsp;&emsp;婆婆躺起觉得不舒服，老三和老幺一起搭手给婆婆调整位置、在背后垫枕头！</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">跳蹬 - 家</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <swiper
            :modules="modules"
            :speed="speed"
            :autoplay="autoplay"
            effect="cube"
            :slides-per-view="1"
            :pagination="{ clickable: true }"
            @click="clicks"
        >
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811028.jpg">
          </swiper-slide>
          <swiper-slide>
            <img class="imgs"
                 src="https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_202205121811029.jpg">
          </swiper-slide>
        </swiper>

        <van-divider>
          <van-button type="warning" size="mini">2022-05-05</van-button>
        </van-divider>
        <div class="common">
          <van-row>
            <van-col span="4">
              <van-button size="mini" type="success">事件：</van-button>
            </van-col>
            <van-col span="20">老大给婆婆喂水。</van-col>
          </van-row>
          <van-row class="marg">
            <van-col span="4">
              <van-button size="mini" type="primary">地点：</van-button>
            </van-col>
            <van-col span="20">跳蹬 - 家</van-col>
          </van-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div :class="endClass.sp" style="width: 95%;margin: 0 auto;margin-top: 10px;">
          <video-player
              ref="videoPlayer"
              @play="playa"
              :options="playerOptions"
              controls
              playsinline
          />
        </div>
        <van-divider>
          <van-button type="warning" size="mini">2022-05-08◇凌晨 我们送婆婆最后一程</van-button>
        </van-divider>

        <div style="color: rgb(255, 255, 255); text-align: center; line-height: 35px;">
          <p :class="endClass.one">
            <span style="font-size: 26px; font-weight: 800;">胡佑碧</span>
            女士</p>
          <p :class="endClass.two">
            愿你一路走好 往生西方极乐世界</p>
          <p style="color: rgb(255, 216, 141); font-size: 18px; font-weight: 600;"
             :class="endClass.three">阿弥陀佛</p></div>
        <p :class="endClass.four" style="text-align: center;font-size: 160px">&#129496;</p>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import {Navigation, Pagination, Scrollbar, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/bundle';

import elementResizeDetectorMaker from 'element-resize-detector'

import {showImagePreview} from 'vant';

export default {
  data() {
    return {
      initialContent: '初始化值',
      ymds: {},
      height: 0,
      handle1Height: 0,
      isStyle: {},
      playerOptions: {
        volume: 0.6,
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: "https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/3-del.mp4" //url地址
        }],
        poster: "https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/115ecf2bf9555f5196d01976b930198.png", //你的封面地址
        width: '100%', //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true  //全屏按钮
        }
      },
      first: {
        one: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-2s': false,
          'animate__slow': false
        },
        two: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-3s': false,
          'animate__slow': false,
        },
        three: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-4s': false,
          'animate__slow': false,
        },
        four: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-5s': false,
          'animate__slow': false,
        },
        five: {
          'animate__animated': false,
          'animate__fadeInLeft': false,
          'animate__delay-5s': false,
          'animate__slow': false,
        },
        six: {
          'animate__animated': false,
          'animate__fadeInLeft': false,
          'animate__slow': false,
        },
        seven: {
          'animate__animated': false,
          'animate__fadeInRight': false,
          'animate__delay-1s': false,
          'animate__slow': false,
        },
        eight: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-2s': false,
          'animate__slow': false,
        },
        lb: {
          'animate__animated': false,
          'animate__fadeInDown': false,
          'animate__slow': false,
        }
      },
      endClass: {
        one: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__slow': false
        },
        two: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-1s': false,
          'animate__slow': false
        },
        three: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-2s': false,
          'animate__slow': false
        },
        four: {
          'animate__animated': false,
          'animate__fadeInUp': false,
          'animate__delay-3s': false,
          'animate__slow': false
        },
        sp: {
          'animate__animated': false,
          'animate__fadeInTopRight': false,
          'animate__slow': false
        }
      },
      homeModules: [Navigation, Pagination, Scrollbar],
      modules: [Pagination, Autoplay],
      autoplay: {
        delay: 3000
      },
      speed: 2000,
      isStop: false,
      bjyyStyle: {
        background: 'rgb(0,0,0,.3)',
        color: 'rgb(255, 255, 255)'
      },
      oneClick: false,
      activeIndex: 0
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  mounted() {
    this.Ymd((new Date('2022-05-06').getTime()) / 1000, new Date().getTime() / 1000);
    let erd = elementResizeDetectorMaker();
    erd.listenTo(this.$refs.texts, element => {
      this.windowScroll(element)
    })
  },
  computed: {
    swiper() {
      return this.$refs.productSwiper
    },
  },
  methods: {
    playa() {
      if (this.isStop) {
        this.bjyyStyle = {
          background: 'rgb(0,0,0,.3)',
          color: 'rgb(255, 255, 255)'
        }
        this.isStop = false;
        this.oneClick = true;
        this.$refs.audio.pause()
      }
    },
    pauses() {
      console.log('视频暂停');
    },
    oneClicks() {
      if (!this.oneClick && !this.isStop && this.activeIndex != 12) {
        this.bjyyStyle = {
          background: 'rgb(255, 234, 65)',
          color: 'rgb(255, 255, 255)',
          'box-shadow': '6px 6px 5px #888888'
        }
        this.isStop = true;
        this.oneClick = true;
        this.$refs.audio.play()
      }
    },
    plays() { //播放暂停控制
      //视频暂停
      let player = this.$refs.videoPlayer.$el.player;
      if (!player.paused()) {
        player.pause()
      }
      //设置对应状态背景
      if (this.isStop) {
        this.bjyyStyle = {
          background: 'rgb(0,0,0,.3)',
          color: 'rgb(255, 255, 255)'
        }
        this.isStop = false;
        this.$refs.audio.pause()
      } else {
        this.bjyyStyle = {
          background: 'rgb(255, 234, 65)',
          color: 'rgb(255, 255, 255)',
          'box-shadow': '6px 6px 5px #888888'
        }
        this.isStop = true;
        this.oneClick = true;
        this.$refs.audio.play()
      }
    },
    clicks(swiper) {
      this.oneClicks()
      let slides = swiper.$el[0].swiper.slides
      let img = []
      for (let i in slides) {
        img[i] = slides[i].children[0].currentSrc
      }
      showImagePreview({
        images: img,
        startPosition: swiper.activeIndex
      });
    },
    onSwiper(swiper) {
      this.firstC(true);
    },
    onSlideChange(swiper) {
      this.activeIndex = swiper.activeIndex
      if (swiper.activeIndex == 0) this.firstC(true);
      if (swiper.activeIndex == 12) this.endClassC(true);
      if (swiper.activeIndex != 0) this.firstC(false);
      if (swiper.activeIndex != 12) {
        this.endClassC(false)
      }
    },
    firstC(val) {
      this.first = {
        one: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-2s': val,
          'animate__slow': val
        },
        two: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-3s': val,
          'animate__slow': val,
        },
        three: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-4s': val,
          'animate__slow': val,
        },
        four: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-5s': val,
          'animate__slow': val,
        },
        five: {
          'animate__animated': val,
          'animate__fadeInLeft': val,
          'animate__delay-5s': val,
          'animate__slow': val,
        },
        six: {
          'animate__animated': val,
          'animate__fadeInLeft': val,
          'animate__slow': val,
        },
        seven: {
          'animate__animated': val,
          'animate__fadeInRight': val,
          'animate__delay-1s': val,
          'animate__slow': val,
        },
        eight: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-2s': val,
          'animate__slow': val,
        },
        lb: {
          'animate__animated': val,
          'animate__fadeInDown': val,
          'animate__slow': val,
        }
      }
    },
    endClassC(val) {
      this.endClass = {
        one: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__slow': val
        },
        two: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-1s': val,
          'animate__slow': val
        },
        three: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-2s': val,
          'animate__slow': val
        },
        four: {
          'animate__animated': val,
          'animate__fadeInUp': val,
          'animate__delay-3s': val,
          'animate__slow': val
        },
        sp: {
          'animate__animated': val,
          'animate__fadeInTopRight': val,
          'animate__slow': val
        }
      }
    },
    windowScroll() {
      if (this.$refs.texts) {
        // 根据左侧其他元素获取按钮距离浏览器窗口左侧的距离
        const clientRect = this.$refs.texts.getBoundingClientRect()
        this.leftSize = clientRect.left
        // 判断左侧距离可视化窗口底部的距离,小于66，将按钮设置绝对定位
        this.height = this.leftDomTop(this.$refs.texts) - 25
      }
      let handle2 = 0;
      if (this.$refs.handle2) {
        // 根据左侧其他元素获取按钮距离浏览器窗口左侧的距离
        const clientRect = this.$refs.handle2.getBoundingClientRect()
        this.leftSize = clientRect.left
        // 判断左侧距离可视化窗口底部的距离,小于66，将按钮设置绝对定位
        handle2 = this.leftDomTop(this.$refs.handle2)
      }
      if (this.$refs.handle1) {
        // 根据左侧其他元素获取按钮距离浏览器窗口左侧的距离
        const clientRect = this.$refs.handle1.getBoundingClientRect()
        this.leftSize = clientRect.left
        // 判断左侧距离可视化窗口底部的距离,小于66，将按钮设置绝对定位
        if (handle2 <= 65) {
          let h = this.leftDomTop(this.$refs.handle1) + 55
          this.isStyle = {
            height: h + 'px',
            overflow: 'auto'
          }
        }
      }
    },
    leftDomTop(dom) {
      const height = window.innerHeight  //可视区窗口高度
      const curDomHeight = dom.offsetHeight
      // const curDomHeight = dom.getBoundingClientRect().height
      const curDomY = dom.getBoundingClientRect().y
      return height - curDomHeight - curDomY
    },
    dateDiff(sDate1, sDate2) {
      let aDate, oDate1, oDate2
      aDate = sDate1.split("-")
      oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])    //转换为12-18-2002格式
      aDate = sDate2.split("-")
      oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
      return parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24)    //把相差的毫秒数转换为天数
    },
    Ymd(startTime, endTime) {
      let runTime = parseInt(endTime - startTime)
      let year = Math.floor(runTime / 86400 / 365)
      runTime = runTime % (86400 * 365)
      let month = Math.floor(runTime / 86400 / 30)
      runTime = runTime % (86400 * 30)
      let day = Math.floor(runTime / 86400)
      this.ymds = {
        year, month, day
      }
    },
    calculateDiffTime(startTime, endTime, type) {
      let runTime = parseInt(endTime - startTime)
      let year = Math.floor(runTime / 86400 / 365)
      runTime = runTime % (86400 * 365)
      let month = Math.floor(runTime / 86400 / 30)
      runTime = runTime % (86400 * 30)
      let day = Math.floor(runTime / 86400)
      runTime = runTime % 86400
      let hour = Math.floor(runTime / 3600)
      runTime = runTime % 3600
      let minute = Math.floor(runTime / 60)
      runTime = runTime % 60
      let second = runTime
      if (type === 1) { // 返回相差年数
        return year + '年'
      } else if (type === 2) { // 返回相差年数月数
        return year + '年' + month + '月'
      } else if (type === 3) { // 返回相差年数月数天数
        return year + '年' + month + '月' + day + '日'
      } else { // 返回相差年数月数天数时分秒
        return year + '年' + month + '月' + day + '日' + hour + '时' + minute + '分' + second + '秒'
      }
    },
  }
}
</script>

<style>
#app {
  background-image: url('https://yqlyy-lyzb.oss-cn-beijing.aliyuncs.com/p/bj.jpg');
}

* {
  margin: 0;
  padding: 0
}

.timeB {
  background: rgb(0, 0, 0, 0.2);
}

.timeY {
  background: rgb(214 70 10 / 20%);
  font-weight: 600;
}

.timeC {
  color: #fff;
  width: 18px;
  text-align: center;
}

.video-js .vjs-big-play-button {
  top: 36% !important;
  left: 40% !important;
}

.common {
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;
  color: #fff;
  font-size: 13px
}

.marg {
  margin-top: 20px
}

.imgs {
  object-fit: cover;
  width: 95%;
  margin-top: 10px;
  margin-left: 2.5%;
  height: 350px;
  border-radius: 5px;
}

.swiper-button-next {
  opacity: 0.6 !important;
  color: #fff;
  animation: mymoveRight 5s infinite;
}

.swiper-button-prev {
  opacity: 0.6 !important;
  color: #fff;
  animation: mymoveLeft 5s infinite;
}

@keyframes mymoveRight {
  0% {
    right: 3%;
  }
  50% {
    right: 0%;
  }
  100% {
    right: 3%;
  }
}

@keyframes mymoveLeft {
  0% {
    left: 3%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 3%;
  }
}

.swiper-button-prev.swiper-button-disabled {
  opacity: 0.15 !important;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s opacity;
  -o-transition: .3s opacity;
  transition: .3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
  text-align: center;
  width: 20%;
  margin: 0 auto;
}

/* 分页器的大小 */
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  opacity: .2;
  border-radius: 50%;
  margin: 0 8px;
}

/* 颜色 */
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #d3d0d0;
  width: 30px;
  border-radius: 10px;
}
</style>